import "slick-carousel";


$('.reviews__slider__slides').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: '.reviews__slider__next',
    prevArrow: '.reviews__slider__prev',
    });  
    
    
/* ________________________ Mobile Menu _______________________ */
const $menu = $('.nav');
$(document).on('mouseup', e => {
    if (!$menu.is(e.target) // if the target of the click isn't the container...
    && $menu.has(e.target).length === 0) // ... nor a descendant of the container
    {
        $menu.find('button.mobile__menu-modal').removeClass('is_active').addClass('is_close');
        $menu.find('#nav-icon1').removeClass('active');
        $menu.find('.main__menu-container').removeClass('is_open');
    }
    });

$('.mobile__menu-modal').on('click', function(){
    $(this).toggleClass('is_open is_close');
    $('#nav-icon1').toggleClass('active');
    $('.main__menu-container').toggleClass('is_open');
    $('body').toggleClass('mobile__menu__is__active'); 
});

$(window).on('load resize', function(){
    // var window_W = $(window).innerWidth();
    $('.mobile__menu-modal').removeClass('is_open').addClass('is_close');
});


$(document).on("scroll", function(){
    // console.log('scrolled');
    $('.mobile__menu-modal').removeClass('is_open').addClass('is_close');
    $('#nav-icon1').removeClass('active');
    $('.main__menu-container').removeClass('is_open');
    $('body').removeClass('mobile__menu__is__active'); 
});

/* ________________________ Mobile Menu _______________________ */

$(window).on('load resize', function(){
    // var window_W = $(window).innerWidth();
    const headerClassHeight = $(".header").innerHeight();
    
    if($(document).scrollTop() > (headerClassHeight - 99)){
        $("header").addClass("shrink");
    }
    
    
    $(document).on("scroll", function(){
    if($(document).scrollTop() > (headerClassHeight - 99)){
        $("header").addClass("shrink");
    }
    else
    {
        $("header").removeClass("shrink");
    }
    });
    
    // console.log(headerClassHeight)
});